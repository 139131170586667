var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"8"}},[_c('h3',{staticClass:"text-h3 mb-8"},[_vm._v(" Информация о клиенте ")]),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('Select',{class:[_vm.$style.select, _vm.$style.item],attrs:{"value":_vm.client,"items":_vm.clients,"label":"Клиент","item-text":"name_client_company","return_object":"","hide-details":"","blue_icon":"","background-color":"#fff","require":"","dense":"","is_autocomplete":""},on:{"input":function($event){return _vm.set_client($event)}}}),_c('v-btn',{class:_vm.$style.btnNewOffer,attrs:{"color":"accent darken-4 ml-4","depressed":""},on:{"click":function($event){_vm.add_new_client = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Добавить нового клиента ")],1)],1),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_offer.client_contact_full_name,"label":"ФИО контактного лица","hide-details":"","rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; } ],"require":"","dense":""},on:{"input":function($event){return _vm.upd_field('client_contact_full_name', $event)}}}),_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_offer.client_contact_email,"label":"E-mail контактного лица","rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; },
              function (v) { return _vm.is_email(v) || _vm.invalid_email; }
            ],"hide-details":"","require":"","dense":""},on:{"input":function($event){return _vm.upd_field('client_contact_email', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phone_mask),expression:"phone_mask"}],class:_vm.$style.item,attrs:{"label":"Телефон контактного лица","hide-details":"","rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; },
              function (v) { return _vm.is_correct_phone(v) || _vm.invalid_phone_number; }
            ],"clearable":"","require":"","dense":""},on:{"input":function($event){return _vm.upd_field('client_contact_phone', $event)}},model:{value:(_vm.new_offer.client_contact_phone),callback:function ($$v) {_vm.$set(_vm.new_offer, "client_contact_phone", $$v)},expression:"new_offer.client_contact_phone"}})],1)])],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',[_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"d-flex mb-2"},[_c('v-btn',{staticClass:"mr-auto",attrs:{"depressed":""},on:{"click":_vm.close}},[_vm._v(" Отменить ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"accent darken-4","depressed":"","disabled":!_vm.is_valid,"type":"submit"}},[_vm._v(" Продолжить ")])],1)],1)],1)],1),_c('AddNewClientDialog',{key:_vm.add_new_client_key,on:{"submit":_vm.add_client,"close":function($event){_vm.add_new_client = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Добавление клиента ")]},proxy:true},{key:"exist_client",fn:function(){return [_c('ClientExistDialog',{on:{"close":function($event){_vm.client_exists_dialog = false},"go_to_exist_client":_vm.go_to_exist_client,"close_client_exists_dialog":_vm.close_client_exists_dialog},model:{value:(_vm.client_exists_dialog),callback:function ($$v) {_vm.client_exists_dialog=$$v},expression:"client_exists_dialog"}})]},proxy:true}]),model:{value:(_vm.add_new_client),callback:function ($$v) {_vm.add_new_client=$$v},expression:"add_new_client"}},[_vm._v("] ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }